import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import UeberMich from '../templates/UeberMich';
import ContactTeaser from '../components/ContactTeaser/ContactTeaser';
import Section from '../components/Section/Section';

const UeberMichPage = ({ data, location }) => {
  const { seoTitle, description, title, sections, qualifications } =
    data.page.frontmatter;

  return (
    <Layout location={location}>
      <Seo
        title={seoTitle || title}
        description={description}
        location={location}
      />
      <UeberMich
        title={title}
        sections={sections}
        qualifications={qualifications}
      />
      <Section>
        <ContactTeaser />
      </Section>
    </Layout>
  );
};

export default UeberMichPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/uebermich/" } }) {
      frontmatter {
        title
        seoTitle
        description
        sections {
          image {
            childImageSharp {
              gatsbyImageData(width: 400, height: 400, placeholder: BLURRED)
            }
          }
          align
          body
        }
        qualifications
      }
      html
    }
  }
`;
