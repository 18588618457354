import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import Section from '../components/Section/Section';

const UeberMich = ({ title, sections, qualifications }) => {
  return (
    <>
      <ContentHeader title={title} />
      {sections?.map(({ body, image, align }, index) => (
        <Section
          key={index}
          image={image && <GatsbyImage image={getImage(image)} alt={''} />}
          align={align}
        >
          <ReactMarkdown>{body}</ReactMarkdown>
        </Section>
      ))}
      <Section type="turquoise-light">
        <ReactMarkdown>{qualifications}</ReactMarkdown>
      </Section>
    </>
  );
};

export default UeberMich;
